import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import {
	getToken
} from '@/utils/auth'

// const About = () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
// const Component = () => import(/* webpackChunkName: "about" */ '@/views/Component.vue')

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'home',
	component: Home,
	meta: {
		title: '旺家·东泰共建数字社区',
		//requiresAuth: true
	}
},
{
	path: '/index',
	name: 'Index',
	component: () => import('@/views/index/index.vue'),
	children: [{
		path: 'demo',
		component: () => import('@/views/index/components/demo.vue'),
	},]
},
{
	//社区管理队伍概况
	path: '/community',
	name: 'Community',
	component: () => import('@/views/community/community.vue'),
	children: [{
		path: 'demo',
		component: () => import('@/views/community/components/demo.vue'),
	},]
},
{
	// 小区
	path: '/xiaoqu',
	name: 'Xiaoqu',
	component: () => import('@/views/xiaoqu/xiaoqu.vue'),
	children: [{
		path: 'demo',
		component: () => import('@/views/xiaoqu/components/demo.vue'),
	},]
},
{
	// 人口概况
	path: '/population',
	name: 'Population',
	component: () => import('@/views/population/population.vue'),
	children: [{
		path: 'demo',
		component: () => import('@/views/population/components/demo.vue'),
	},]
},
{
	// 东泰社区司法调解汇总
	path: '/judicialMediation',
	name: 'JudicialMediation',
	component: () => import('@/views/judicialMediation/judicialMediation.vue'),
	children: [{
		path: 'demo',
		component: () => import('@/views/judicialMediation/components/demo.vue'),
	},]
},
{
	// 民生警情
	path: '/mingshengJq',
	name: 'MingshengJq',
	component: () => import('@/views/mingshengJq/mingshengJq.vue'),
	children: [{
		path: 'demo',
		component: () => import('@/views/mingshengJq/components/demo.vue'),
	},]
},
{
	// 党务建设
	path: '/dangwuJianshe',
	name: 'DangwuJianshe',
	component: () => import('@/views/dangwuJianshe/dangwuJianshe.vue'),
	children: [{
		path: 'demo',
		component: () => import('@/views/dangwuJianshe/components/demo.vue'),
	},]
},
{
	// 数据统计
	path: '/dataStatistics',
	name: 'DataStatistics',
	component: () => import('@/views/dataStatistics/dataStatistics.vue'),
	children: [{
		path: 'demo',
		component: () => import('@/views/dataStatistics/components/demo.vue'),
	},]
},
{
	// 党员名册
	path: '/memberRegister',
	name: 'MemberRegister',
	component: () => import('@/views/memberRegister/memberRegister.vue'),
	children: [{
		path: 'demo',
		component: () => import('@/views/memberRegister/components/demo.vue'),
	},]
},
{
	// 网格编辑页面
	path: '/wjawards',
	name: 'wjawards',
	component: () => import('@/views/memberRegister/wjawards.vue'),

},
{
	// 疫情防控数据展示
	path: '/yiQing',
	name: 'YiQing',
	component: () => import('@/views/yiQing/yiQingNew.vue'),
	children: [{
		path: 'demo',
		component: () => import('@/views/yiQing/components/demo.vue'),
	},]
},
{
	// 东泰社区编外工作人员量化考核流程
	path: '/kaoheLC',
	name: 'kaoheLC',
	component: () => import('@/views/kaoheLC/kaoheLC.vue'),
	children: [{
		path: 'demo',
		component: () => import('@/views/yiQing/components/demo.vue'),
	},]
},
{
	// 东泰社区管理架构
	path: '/jiagouTu',
	name: 'jiagouTu',
	component: () => import('@/views/jiagouTu/jiagouTu.vue'),
	children: [{
		path: 'demo',
		component: () => import('@/views/yiQing/components/demo.vue'),
	},]
},
{
	path: '/Demo',
	name: 'Demo',
	meta: {
		title: '测试demo',
		// requiresAuth: true
	},
	component: () => import('@/views/Demo/Demo.vue')
},
{
	// 我的幸福家园
	path: '/happyHome',
	name: 'happyHome',
	component: () => import('@/views/happyHome/index.vue'),
	children: [{
		path: 'demo',
		component: () => import('@/views/happyHome/components/demo.vue'),
	},]
},
{
	// 获奖记录
	path: '/awards',
	name: 'awards',
	component: () => import('@/views/awards/index.vue'),

},
{
	// 微心愿
	path: '/microWish',
	name: 'microWish',
	component: () => import('@/views/microWish/index.vue'),
	children: [{
		path: 'demo',
		component: () => import('@/views/yiQing/components/demo.vue'),
	},]
},
// 微心愿编辑页
{
	path: '/editMicroWish',
	name: 'editMicroWish',
	component: () => import('@/views/editMicroWish/index.vue'),
	children: [{
		path: 'demo',
		component: () => import('@/views/yiQing/components/demo.vue'),
	}]
},
{
	// 微心愿
	path: '/homeImg',
	name: 'homeImg',
	component: () => import('@/views/homeImg/index.vue')
},
{
	// 我的文创产品助销图片编辑
	path: '/marketingaid',
	name: 'marketingaid',
	component: () => import('@/views/marketingaid/index.vue')
},

{
	path: '/404',
	component: (resolve) => require(['@/views/error/404'], resolve),
	hidden: true
},
{
	path: '/401',
	component: (resolve) => require(['@/views/error/401'], resolve),
	hidden: true
},

]

const router = new VueRouter({
	mode: 'hash',
	routes
})


router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) { // 哪些需要验证
		if (!getToken()) { // token存在条件
			next({
				path: '/user/login', // 验证失败要跳转的页面
				query: {
					redirect: to.fullPath // 要传的参数
				}
			})
		} else {
			next()
		}
	} else {
		next() // 确保一定要调用 next()
	}
})
export default router

import request from '@/utils/request'

// 查询展示数据列表
export function listData(query) {
  return request({
    url: '/system/data/list',
    method: 'get',
    params: query
  })
}

// 查询展示数据详细
export function getData(dataId) {
  return request({
    url: '/system/data/' + dataId,
    method: 'get'
  })
}

// 回显数据字典
export function getvalue(datas, value) {
  var actions = '';
  Object.keys(datas).some((key) => {
    if (datas[key].dataId == ('' + value)) {
      actions = datas[key].dataDetailed;
      // alert(actions)
      //	return true;
      return actions;
    }
  })
  return actions;
}

// 查询展示统计数据详细
export function listCountData(partyUnitId) {
  return request({
    url: '/szdt/payroll/anon/countPartyPayroll',
    method: 'get',
    params: {
      partyUnitId,

    }
  })
}
// 查询党组织详细信息
export function listCountDataDetail(dataId) {
  return request({
    url: '/szdt/branch/anon/' + dataId,
    method: 'get'
  })
}

// 批量修改
export function batchDataDetail(data) {
  return request({
    url: '/system/data/batch',
    method: 'put',
    data: data
  })
}


export function getBranchDetail(type, partyUnitId) {
  return request({
    url: `/szdt/branchDetail/anon/${type}/${partyUnitId}`,
    method: 'get'
  })
}

export function postDataDetail(data) {
  return request({
    url: '/system/data',
    method: 'post',
    data
  })
}

export function deleteDataDetail(id) {
  return request({
    url: `/system/data/${id}`,
    method: 'delete',
  })
}

export function putDataDetail(data) {
  return request({
    url: '/system/data',
    method: 'put',
    data
  })
}
import Cookies from 'js-cookie'

const TokenKey = 'pass'
const readPass = 'wangjia123'
const editPass = 'wangjia456'

export {
  readPass, editPass
}

export function existSystem() {
  return Cookies.get(TokenKey) == editPass;
}

<!-- 我的红色网格 -->
<template>
    <div class='redGridBox'>
        <div class="page-header" @click="redGridwg">
            <div class="ph-label one">
                <i class="el-icon-menu"></i>
                <span>网格</span>
                <span class="ph-num">{{lhDataList?lhDataList[0].dataValue:''}}</span>
                <span>个</span>
            </div>
            <div class="ph-label two">
                <i class="el-icon-s-custom"></i>
                <span>联户长</span>
                <span class="ph-num">{{lhDataList?lhDataList[1].dataValue:''}}</span>
                <span>个</span>
            </div>
        </div>
        <div class="page-content">
            <div class="gridEcharts" ref="gridEcharts"></div>
        </div>
    </div>
</template>

<script>
export default {
    props:['redGridListInfo','redGridList'],
    data() {
        return {
            lhDataList:[],//数据
            wgDataList: []
        };
    },
    created(){
        this.lhDataList = this.redGridList
        this.wgDataList = this.redGridListInfo
    },
    methods: {
        getInit(){
            let color = ["#2363F1", "#F56B03", "#24D8D7","#a1a701","#6d4271","#01689b","#ffda08","#1f933a","#5b9bd4","#eb7bda","#68dbb2","#e54f2c"];
            let yData = this.wgDataList
            this.wgDataList.forEach((item,i)=>{
                yData[i].value = item.dataValue
                yData[i].name = item.dataName
            })
            
            let myChart = this.$echarts.init(this.$refs.gridEcharts);
            let option = {
                color: color,
                legend: {
                    top: 'bottom',
                    left: 'center',
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        //图例文字的样式
                        color: "#fff",
                        fontSize: 11,
                        padding: 2,
                    },
                    formatter: function (name) {
                        return "" + name;
                    },
                },
                series: [{
                    type: 'pie',
                    radius: ["50%", "65%"],
                    minAngle: 1, //最小的扇区角度（0 ~ 360）
                    top: '-50',
                    avoidLabelOverlap: false,
                    label: {
                        normal: {
                        show: false,
                        position: "center",
                        formatter: "{text|{b}}\n{c}",
                        rich: {
                            text: {
                                color: "#fff",
                                fontSize: 14,
                                align: "center",
                                verticalAlign: "middle",
                                padding: 8,
                            },
                            value: {
                                color: "#fff",
                                fontSize: 14,
                                align: "center",
                                verticalAlign: "middle",
                            },
                        },
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                fontSize: 20,
                            },
                        },
                    },
                    data: yData
                }]
            };
            myChart.setOption(option);
            myChart.currentIndex = 0;
            let time = undefined
            setTimeout(() => {
                myChart.on("mouseover", function (params) {
                    clearInterval(time)
                    let allindex = []
                    yData.forEach((item,i)=>{
                        allindex.push(i)
                    })
                    myChart.dispatchAction({
                        type: "downplay",
                        dataIndex: allindex,
                    });
                    myChart.dispatchAction({
                        type: "highlight",
                        seriesIndex: 0,
                        dataIndex: params.dataIndex,
                    })
                    myChart.currentIndex = params.dataIndex
                });
                myChart.on("mouseout", function () {
                    time = setInterval(function () {
                        let dataLen = option.series[0].data.length;
                        // 取消之前高亮的图形
                        let allindex = []
                        yData.forEach((item,i)=>{
                            allindex.push(i)
                        })
                        myChart.dispatchAction({
                            type: "downplay",
                            dataIndex: allindex,
                        });
                        myChart.currentIndex = (myChart.currentIndex + 1) % dataLen;
                        // 高亮当前图形
                        myChart.dispatchAction({
                            type: "highlight",
                            seriesIndex: 0,
                            dataIndex: myChart.currentIndex,
                        });
                    }, 3000);
                });
                myChart.dispatchAction({
                    type: "highlight",
                    seriesIndex: 0,
                    dataIndex: 0,
                });   
            });
            time = setInterval(function () {
                let dataLen = option.series[0].data.length;
                // 取消之前高亮的图形
                let allindex = []
                yData.forEach((item,i)=>{
                    allindex.push(i)
                })
                myChart.dispatchAction({
                    type: "downplay",
                    dataIndex: allindex,
                });
                myChart.currentIndex = (myChart.currentIndex + 1) % dataLen;
                // 高亮当前图形
                myChart.dispatchAction({
                    type: "highlight",
                    seriesIndex: 0,
                    dataIndex: myChart.currentIndex,
                });
            }, 3000);

            window.addEventListener("resize", function () {
                myChart.resize();
            });
        },
        // header click
        redGridwg(){
            this.$emit('redGridwg')
        },
        redGridyuan(){
            this.$emit('redGridyuan')
        }
    },
    mounted() {
        this.getInit()
    },
}
</script>
<style lang='scss' scoped>
    @import './index.scss';
</style>
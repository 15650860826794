<!--  -->
<template>
    <div class='main'>
        <div class="header">
            <img class="header-logo" src="../../assets/image/wjlogo.png" /> 旺家·东泰共建数字社区
            <!-- 关闭 刷新 -->
            <div class="idx-middlesx-box3">
                <div class="idx-middle-1">
                <img class="fh" src="@/assets/icon/gb.png" v-show="gb" @click="close"/>
                <img class="sx" src="@/assets/icon/sx.png" alt="" @click="refresh" />
                </div>
            </div>
        </div>
        <div class="m-main">
            <!-- 左边 -->
            <div class="m-left ml">
                <div class="page-box">
                    <div class="home-page page-one">
                        <div class="homepage-title" @click="gotoLink('home')">我的幸福家园</div>
                        <div class="homepage-box" >
                            <div class="onecontent" v-for="(item, i) in pagedataList" :key="i" @click="pageClick">
                                <div class="content-title">{{item.dataName?item.dataName:''}}<span v-if="item.dataField1">({{item.dataField1?item.dataField1:''}})</span></div>
                                <div class="content-num">{{item.dataValue?item.dataValue:''}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="home-page page-two">
                        <div class="homepage-title" @click="gotoLink('redgrid')">我的红色网格</div>
                        <div class="redGrid">
                            <redGrid 
                                v-if="redGridList&&redGridListInfo&&redGridListInfo.length>0&&redGridList.length>0" 
                                :redGridListInfo="redGridListInfo" :redGridList="redGridList"
                                @redGridwg="redGridwg"
                            >
                            </redGrid>
                        </div>
                    </div>
                </div>
                <div class="page-label"></div>
            </div> 
            <!-- 中间 -->
            <div class="m-middle">
                <div class="middle-top">
                    <div class="middle-time">
                        <Date></Date>
                    </div>
                    <div class="middle-btn" v-if="isUpdateimg">
                        <el-button @click="gotoHomeimg">编辑信息</el-button>
                    </div>
                </div>
                <div class="middle-img">
                    <!-- <el-upload
                        class="upload-demo"
                        action="http://192.168.2.10:8060/prod-api/common/upload"
                        multiple
                        :limit="3">
                        <el-button size="small" type="primary">点击上传</el-button>
                    </el-upload> -->
                    <el-carousel :interval="5000" arrow="always" :autoplay="false">
                        <el-carousel-item v-for="item in homeswiper" :key="item" >
                            <img :src="item.dataDetailed" />
                        </el-carousel-item>
                    </el-carousel>
                    <!-- <img src="@/assets/image/homepage.png" /> -->
                </div>
            </div> 
            <!-- 右边 -->
            <div class="m-right mr">
                <div class="page-label"></div>
                <div class="page-box">
                    <!-- 我们的党建结对共建 -->
                    <div class="home-page rightthree" >
                        <div class="homepage-title" @click="gotoLink('partybuil')">我们的党建结对共建</div>
                        <div class="pb-top">
                            <div class="pb-title">
                                <div>幸福东泰爱心包裹共建项目</div>
                                <div>东泰社区党委爱心包裹发放情况统计</div>
                            </div>
                            <div class="pb-header" @click="partyBuilding" v-if="partyBuildingData">
                                <div class="pbh-page" v-for="(item, i) in partyBuildingData" :key="i">
                                    <div class="pbh-num">{{item.dataValue?item.dataValue:''}}</div>
                                    <div>{{item.dataValue?item.dataName:''}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="pb-echarts" @click="partyBuildingListFun">
                            <div class="echarts-page">
                                <partyBuilding v-if="partyBuildingList[0]&&partyBuildingList[0].length>0" :allDatanunm="partyBuildingData[0].dataValue?partyBuildingData[0].dataValue:''" :partyBuildingList="partyBuildingList[0]"></partyBuilding>
                            </div>
                            <div class="echarts-page">
                                <partyBuilding v-if="partyBuildingList[1]&&partyBuildingList[1].length>0" :allDatanunm="partyBuildingData[0].dataValue?partyBuildingData[0].dataValue:''"  :partyBuildingList="partyBuildingList[1]"></partyBuilding>
                            </div>
                        </div>
                    </div>
                    <!-- 我们的文创产品助销 -->
                    <div class="home-page rightfour">
                        <div class="homepage-title" @click="culturalCreativityFun" @closeDialog="closeDialog">我们的文创产品助销</div>
                        <culturalCreativity v-if="dataOptions&&dataOptions.length>0" :culturalCreativityData="dataOptions"></culturalCreativity>
                    </div>
                </div>
            </div> 
        </div>
        <!-- 我们的文创产品助销 弹窗 -->
        <dialogCom 
            v-if="isDialog"
            :dialog="isDialog"
            :dialogTitle="dialogTitle"
            :dataOptions="dataOptions"
            @partyDialogFun="partyDialogFun"
            @gotomarketingaid="gotomarketingaid">
        </dialogCom>
        <el-dialog
            :visible.sync="culturalVisible"
            width="54vw"
            :append-to-body="true"
            :before-close="handleClose"
            :close-on-click-modal="false"
            class="culturalDialog"
        >
            <div class="dialog-bg">
                <img class="back-ground-image" src="@/assets/image/homeclosebg.png" alt="" />
            </div>
            <!-- 我们的文创产品 -->
            <div class="dialog-box ">
                <div class="dialog-box-top">
                    <div class="dialog-title">{{ culturalDialogData.title }}</div>
                </div>
                <div class="dialog-box-bottom">
                <ul class="dialog_form">
                    <li class="form_item text">
                        <label>内容：</label>
                        <textarea v-model="culturalDialogData.culturalDesc" />
                    </li>
                    <li class="form_item">
                        <label>团购量：</label>
                        <input type="text" v-model="culturalDialogData.teamBuy" />
                    </li>
                    <li class="form_item">
                        <label>积分兑换：</label>
                        <input type="text" v-model="culturalDialogData.integral" />
                    </li>
                    <li class="form_item">
                        <label>销售：</label>
                        <input type="text" v-model="culturalDialogData.sales" />
                    </li>
                </ul>
                <div class="dialog_btn" style="display: block">
                    <div style="margin-right: 50px" @click="cancelCultural">取消</div>
                    <div @click="culturalSublimt">确定修改</div>
                </div>
                </div>
            </div>
        </el-dialog>
        <!-- 数据修改弹框 -->
        <Dialog
            v-if="edit_dialog"
            :dialog="edit_dialog"
            :dialogTitle="dialogTitle"
            :dataList="dialogList"
        >
        </Dialog>
        <!-- 登录弹框 -->
        <el-dialog
            title=""
            :visible.sync="loginVisible"
            width="50%"
            :append-to-body="true"
            :before-close="handleClose"
            :close-on-click-modal="false"
        >
            <div class="dialog-bg">
                <img class="back-ground-image" src="@/assets/image/idx-dl2.png"/>
            </div>
            <div class="dialog-box">
                <div class="dialog-box-top">您没有查看权限，请输入密码才能查看哦</div>
                <div class="dialog-box-bottom">
                <div class="login-form">
                    <input type="password" v-model="password" placeholder="密码" id="password"/>
                    <button id="login" @click="login">登录</button>
                </div>
                </div>
            </div>
        </el-dialog>
        <!-- 我们的党建结对共建 -->
        <partyBuildingDialog
            v-if="ishowPartybuilding"
            :dialog="ishowPartybuilding"
            :dialogTitle="dialogTitle"
            :dataList="dialogList"
        >
        </partyBuildingDialog>
    </div>
</template>

<script>
import Date from "@/views/home/components/date.vue";
import redGrid from '@/views/home/components/redGrid.vue'
import partyBuilding from '@/views/home/components/partyBuilding.vue'
import culturalCreativity from '@/views/home/components/culturalCreativity.vue'
import dialogCom from '@/views/home/components/dialog.vue'
import Dialog from "@/components/dialog/dialog.vue";
import partyBuildingDialog from "@/views/home/components/partyBuildingDialog.vue";

import { listData } from "@/api/dtdata";
import {getpartyList, getculturalList, updatecultural, uploadimg} from '@/api/home.js'
export default {
    inject: ["reload"], //注入reload方法
    components: {
        Date,
        redGrid,
        partyBuilding,
        culturalCreativity,
        dialogCom,
        Dialog,
        partyBuildingDialog
    },
    data() {
        return {
            gb: false,
            isDialog: false,
            dialogTitle: '',
            edit_dialog: false,// 弹窗
            pagedataList: [],
            redGridListInfo: [],// 我的红色网格数据
            redGridList:[],
            loginVisible: false,//是否登录
            password: '', // 密码
            dialogList: [],
            partyBuildingData:[],// 党建建党数据
            partyBuildingList:[[],[]],// 党建建党列表
            ishowPartybuilding:false,//党建建党列表弹窗
            dataOptions: [],//助销
            culturalVisible: false,
            culturalDialogData: {},
            homeswiper:[],//轮播图
            loading: null,
            isUpdateimg: false
        };
    },
    methods: {
        // 助销
        async getculturalList(){
            await getculturalList().then(res=>{
                if(res.code == 200){
                    this.dataOptions = res.rows
                }
            }).finally(()=>{
                this.loading.close();
            })
        },
        gotomarketingaid(datas){
            let islogin = this.islogin()
            if(islogin == 0){
                this.loginVisible = true
            }else if(islogin == 1){
                this.$router.push({path:'/marketingaid',query:{sel: datas}})
            }
        },
        // 我们的文化产品助销内部弹窗
        partyDialogFun(datas){
            let islogin = this.islogin()
            if(islogin == 0){
                this.loginVisible = true
            }else if(islogin == 1){
                this.culturalDialogData.id = datas.id
                this.culturalDialogData.title = datas.title
                this.culturalDialogData.culturalDesc = datas.culturalDesc
                this.culturalDialogData.teamBuy = datas.teamBuy*1
                this.culturalDialogData.sales = datas.sales*1
                this.culturalDialogData.integral = datas.integral*1
                this.culturalVisible = true
            }
            
        },
        // 取消
        cancelCultural(){
            this.culturalVisible = false
        },
        // 修改
        culturalSublimt(){
            updatecultural(this.culturalDialogData).then(res=>{
                if(res.code == 200){
                    this.$message({
                        message: "修改成功",
                        type: "success",
                    });
                    this.culturalVisible = false
                    // this.getculturalList()
                    
                    setTimeout(() => {
                        this.$router.go(0); //刷新当前页面
                    }, 400);
                }else{
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                }
            })
        },
        // 我们的党建结对共建
        partyBuildingListFun(){
            this.dialogTitle = '我们的党建结对共建'
            let newDialogList = this.partyBuildingList[0]
            this.partyBuildingList[1].forEach(item=>{
                newDialogList.push(item)
            })
            this.dialogList = []
            this.dialogList = newDialogList
            this.showDialog('dj')
        },
        // 我们的党建结对共建 header
        partyBuilding(){
            this.dialogTitle = '我们的党建结对共建'
            this.partyBuildingData.forEach(item=>{
                item.dataDetailed = item.dataValue?item.dataValue:0
            })
            let newDialogList = []
            this.partyBuildingData.forEach(item => {
                item.dataDetailed = item.dataValue?item.dataValue:0
                newDialogList.push(item)
            });
            this.dialogList = []
            this.dialogList = newDialogList
            this.showDialog()
        },
        // 我的红色网格
        redGridwg(){
            this.dialogTitle = '我的红色网格'
            this.redGridList.forEach(item=>{
                item.dataDetailed = item.dataValue?item.dataValue:0
            })
            let newDialogList = this.redGridList
            this.redGridListInfo.forEach(item => {
                item.dataDetailed = item.dataValue?item.dataValue:0
                newDialogList.push(item)
            });
            this.dialogList = newDialogList
            this.showDialog()
        },
        // 我的幸福家园
        pageClick(){
            this.dialogTitle = '我的幸福家园'
            this.pagedataList.forEach(item=>{
                item.dataDetailed = item.dataValue?item.dataValue:0
            })
            this.dialogList = this.pagedataList
            this.showDialog()
        },
        showDialog(datas){
            let islogin = this.islogin()
            if(islogin == 0){
                this.loginVisible = true
            }else if(datas == 'dj' && islogin== 1){
                this.ishowPartybuilding = true
            }else if(islogin == 1){
                this.edit_dialog = true
            }
        },
        // 获取我的幸福家园数据
        async listData(dataModule, dataTyp, dataIndex){
            await listData({
                dataModule: dataModule,
                dataTyp: dataTyp
            }).then(res=>{
                if(res.code == 200){
                    if(dataIndex == 1){
                        this.pagedataList = []
                        res.rows.forEach(item => {
                            item.dataValue = item.dataDetailed
                            this.pagedataList.push(item)
                        });
                    }else if(dataIndex == 2){
                        this.redGridListInfo = []
                        res.rows.forEach(item => {
                            item.dataValue = item.dataDetailed
                            this.redGridListInfo.push(item)
                        });
                    }else if(dataIndex == 2.1){
                        this.redGridList = []
                        res.rows.forEach(item => {
                            item.dataValue = item.dataDetailed
                            this.redGridList.push(item)
                        });
                    }else if(dataIndex == 3){
                        this.partyBuildingData = []
                        res.rows.forEach(item => {
                            item.dataValue = item.dataDetailed
                            this.partyBuildingData.push(item)
                        });
                    }else if(dataIndex == 4){
                        // 轮播图
                        this.homeswiper = []
                        res.rows.forEach(item => {
                            if(item.dataDetailed && item.dataField1 && item.dataField1!='false'){
                                this.homeswiper.push(item)
                            }
                        });
                    }
                }
            })
        },
        // 获取我们的党建结对共建
        async getpartyList(){
            await getpartyList().then(res=>{
                if(res.code == 200) {
                    res.rows.forEach((item, i)=>{
                        if(i<9){
                            this.partyBuildingList[0].push(item)
                        }else{
                            this.partyBuildingList[1].push(item)
                        }
                    })
                    
                }
            })
        },
        
        refresh() {
            this.$router.go(0); //刷新当前页面
            // this.reload();
        },
        // 清除cookies
        close() {
            this.$cookies.remove("pass"); // return this
            this.$router.go(0); //刷新当前页面
        },
        // title click
        culturalCreativityFun(){
            this.dialogTitle = '我们的文创产品助销'
            this.isDialog = true
        },
        closeDialog(){
            this.isDialog = false
        },
        
        // 关闭登录
        handleClose(done) {
            done();
            this.loginVisible = false;
        },
        cookies() {
            // 判断cookie
            if (`${this.readPass},${this.editPass}`.indexOf(this.$cookies.get("pass")) > -1 ) {
                this.gb = true; // 锁
            } else {
                this.gb = false; // 锁
            }
        },
        // 登录
        login() {
            const pass = this.password; //获取到值
            if (pass ==  this.readPass || pass == this.editPass) {
                this.$message({
                    message: "登录成功",
                    type: "success",
                });
                this.gb = true
                this.loginVisible = false;
                this.$cookies.set("pass", pass); //return this
                this.$router.go(0); //刷新当前页面
            } else {
                this.$message.error("密码错误，请重新输入");
            }
        },
        // 跳转我的幸福家园
        gotoLink(types){
            // types: home 家园, partybuil 党建
            let islogin = this.islogin()
            if(islogin>0){
                if(types == 'home'){
                    this.$router.push({path:'/happyHome'})
                }else if(types == 'partybuil'){
                    this.$router.push({path:'/microWish'})
                }else if(types == 'redgrid'){
                    this.$router.push({path:'/memberRegister'})
                }
            }else{
                this.loginVisible = true
            }
        },
        // 跳转首页图片管理
        gotoHomeimg(){
            this.$router.push({path:'/homeImg'})
        },
        // 判断是否登录
        islogin(){
            // 1为编辑，2为阅读，0为未登录
            const pass = this.$cookies.get('pass')
            let returndata = 0
            if(pass ==  this.readPass || pass == this.editPass){
                if(this.existSystem()){
                    returndata = 1
                }else{
                    returndata = 2
                }
            }else{
                returndata = 0
            }
            return returndata
        }
    },
    async mounted() {
        this.loading = this.$loading({
            lock: true,
            text: '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        this.isUpdateimg = this.islogin() == 1?true:false
        this.gb = this.islogin()>0?true:false
        this.listData('wj_home_data','home_happy_home', 1) // 我的幸福家园
        this.listData('wj_home_data','home_red_grid_info', 2) // 我的红色网格
        this.listData('wj_home_data','home_red_grid', 2.1) // 我的红色网格
        this.listData('wj_home_data','home_djjd', 3) // 我们的党建结对共建
        this.listData('wj_home_data','home_banner', 4) // 中间轮播图
        this.getpartyList()// 我们的党建结对共建
        this.getculturalList()//我们的文化产品助销
    },
}
</script>
<style lang='scss' scoped>
    @import './index.scss';
</style>
<template>
  <div id="app">
    <!-- <router-view /> -->
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
import { getConfigKeypc, getConfigKeyiphone } from "@/api/data";
export default {
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      show: false,
      list: [],
      listxq: "",
      pcUrl: undefined,
      iphoneUrl: undefined,
      isRouterAlive: true, //控制视图是否显示的变量
    };
  },

  created() {},
  methods: {
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true; //再打开
      });
    },
  },
};
</script>

<style lang="scss">
@import url("./assets/css/reset.css");
@import url("./assets/font_icon/iconfont.css");
* {
  margin: 0;
  padding: 0;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
/*Font*/
@font-face {
  font-family: "hy";
  src: url("./assets/font/hy.eot");
  src: url("./assets/font/hy.otf") format("otf"),
    url("./assets/font/hy.eot?#iefix") format("embedded-opentype"),
    url("./assets/font/hy.woff") format("woff"),
    url("./assets/font/hy.woff2") format("woff2"),
    url("./assets/font/hy.ttf") format("truetype"),
    url("./assets/font/hy.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
.clear {
  clear: both;
}
a {
  text-decoration: none;
  color: #000;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #00214A;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  background-color: #022E58;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: #00214A;
}
div {
  box-sizing: border-box;
}
.container
  .idx-content
  .idx-course-list
  .idx-course-listbox[data-v-fae5bece]
  .el-tabs__nav-wrap {
  overflow: hidden;
  margin-bottom: -1px;
  position: relative;
  padding-left: 247px;
  z-index: 0;
}
.fixed {
  position: fixed;
  right: 0px;
  bottom: 110px;
  ul li {
    width: 65px;
    height: 65px;
    padding: 10px 5px;
    margin-bottom: 3px;
    cursor: pointer;
    background: linear-gradient(
      -54deg,
      rgba(30, 112, 234, 0.89),
      rgba(72, 140, 240, 0.89)
    );
    border-radius: 4px;
    position: relative;
    .kf-img {
      width: 50px;
      height: 50px;
      margin-left: 9px;
    }
    p {
      color: #ffffff;
      font-size: 14px;
      text-align: center;
    }
    ::v-deep .kf-box {
      width: 500px;
      height: 750px;
      border-radius: 10px;
      background: #ffffff;
      border: 1px solid #cccccc;
      overflow: hidden;
      position: absolute;
      top: -520px;
      right: 80px;
      z-index: 9999999999;
      .kf-box-1 {
        width: 100%;
        height: 50px;
        line-height: 50px;
        color: #ffffff;
        font-size: 18px;
        text-indent: 10px;
        background: #0074ff;
        position: relative;
        img {
          width: 20px;
          height: 20px;
          position: absolute;
          top: 14px;
          right: 17px;
        }
      }
      .kf-box-2 {
        width: 90%;
        height: 600px;
        margin: 0 auto;
        // border: 1px solid #cccccc;
        .kf-box-time {
          width: 100%;
          height: 100px;
          line-height: 100px;
          text-align: center;
        }
        .kf-box-title {
          width: 100%;
          height: 60px;
          line-height: 60px;
          text-align: center;
          border: 1px solid #ddd;
          color: #000;
          font-size: 20px;
          font-weight: bold;
          border-radius: 10px;
        }
        .kf-box-3 {
          width: 100%;
          height: 400px;
          // overflow-y: auto;
          margin: 0 auto;
          margin-top: 10px;
          border-radius: 10px;
          border: 1px solid #ddd;
          .kf-box-title2 {
            border: none;
          }
          .kf-box-3box {
            width: 100%;
            height: 320px;
            overflow-y: auto;
          }

          ::v-deep .el-collapse-item__header {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            height: 48px;
            line-height: 48px;
            background-color: #fff;
            color: #303133;
            cursor: pointer;
            border-bottom: 1px solid #ebeef5;
            font-size: 13px;
            font-weight: 500;
            -webkit-transition: border-bottom-color 0.3s;
            transition: border-bottom-color 0.3s;
            outline: 0;
            text-indent: 10px;
          }
          ::v-deep .el-collapse-item__content {
            padding-bottom: 25px;
            font-size: 13px;
            color: #303133;
            line-height: 1.769230769230769;
            padding: 10px;
            padding-bottom: 20px;
          }
        }
        .kf-box-title3 {
          margin-top: 20px;
          display: inline-block;
        }
      }
    }
  }
  .fd {
    width: 65px;
    height: 65px;
    padding: 5px 5px;
    background: none;
  }
}

.header {
  width: 100%;
  height: 10vh;
  line-height: 10vh;
  background: -webkit-gradient(linear,left bottom,left top,from(#c0d9ff),to(#fff));
  background: linear-gradient(0deg,#c0d9ff,#fff);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 2.3vw;
  text-align: center;
  font-family: Microsoft YaHei;
  font-weight: 700;
  text-shadow: 0 10px 7px rgb(0 0 0 / 17%);
  letter-spacing: .8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  .header-logo{
    width: 2.5vw;
    height: 2.5vw;
    margin-right: 1vw;
  }
}
.time-box {
  width: 25vw;
  height: 7vh;
  // border: 1px solid teal;
  position: fixed;
  top: 7vh;
  right: 3vw;
  z-index: 99;
}
.page-title{
  height: 7vh;
  line-height: 7vh;
  // border: 1px solid teal;
  position: fixed;
  top: 7vh;
  left: 3vw;
  z-index: 99;
  color: #ffd901;
  font-size: 2.4vh;
}
</style>

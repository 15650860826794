<!-- 我们的党建结对共建 -->
<template>
    <div class="partyBuilding">
        <div class="mainEcharts" ref="mainEcharts"></div>
    </div>
</template>

<script>
export default {
    props:['partyBuildingList','allDatanunm'],
    data() {
        return {
            dataList:[]
        };
    },
    methods: {
        
    },
    created(){
        this.dataList = this.partyBuildingList
    },
    mounted() {
        let baifenbi = [];
        let grayBar = [];
        let xingm = [];

        this.dataList.forEach(item=>{
            baifenbi.push(item.personCount>10?10:item.personCount)
            grayBar.push(10)// 按照最高10来放置
            xingm.push(item.name)
        })
        
        let myChart = this.$echarts.init(this.$refs.mainEcharts);
        let option = {
          grid: {
            left: "50%", //如果离左边太远就用这个......
            right: "20%",
            bottom: "0%",
            top: "0%",
            containLabel: true,
          },
          xAxis: [
            {
              show: false,
            },
            {
              show: false,
            },
          ],
          yAxis: {
            type: "category",
            axisLabel: {
              show: true, //让Y轴数据不显示
            },
            axisTick: {
              show: false, //隐藏Y轴刻度
            },
            axisLine: {
              show: false, //隐藏Y轴线段
            },
          },
          series: [
            {
                show: true,
                type: "bar",
                barGap: "-100%",
                barWidth: "20%", //统计条宽度
                itemStyle: {
                    normal: {
                    barBorderRadius: 150,
                    fontSize: 10,
                    color: "#0E1F4A",
                    },
                },
                label: {
                    normal: {
                        show: true,
                        textStyle: {
                            color: "#fff", //百分比颜色
                            fontSize: 12,
                        },
                        position: "right",
                        formatter: function (data) {
                            return baifenbi[data.dataIndex];
                        },
                    },
                },
                data: grayBar,
            },
            //蓝条
            {
              show: true,
              type: "bar",
              barGap: "-100%",
              barWidth: "25%", //统计条宽度
              itemStyle: {
                normal: {
                  barBorderRadius: 50, //统计条弧度
                  color: {
                    colorStops: [
                      {
                        offset: 0,
                        color: "#025EEA", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#00C0FA", // 100% 处的颜色
                      },
                    ],
                    globalCoord: false, // 缺省为 false
                  },
                },
              },
              max: 1,
              label: {
                normal: {
                  show: true,
                  textStyle: {
                    fontSize: 11,
                    color: "#fff", //百分比颜色
                  },
                  position: "left",
                  formatter: function (data) {
                    //富文本固定格式{colorName|这里填你想要写的内容}
                    return xingm[data.dataIndex];
                  },
                },
              },
              data: baifenbi,
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
            myChart.resize();
        });
    },
}
</script>
<style lang='scss' scoped>
    @import './index.scss';
</style>
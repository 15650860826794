<!-- 我们的文创产品助销 -->
<template>
    <div class='culturalCreativity'>
        <div class="culturalEcharts" ref="culturalEcharts"></div>
    </div>
</template>

<script>
export default {
    props:['culturalCreativityData'],
    components: {},
    data() {
        return {
            dataOptions: []
        };
    },
    created(){
        this.dataOptions = this.culturalCreativityData
    },
    methods: {
    },
    mounted() {
        let color = ["#2363F1", "#F56B03", "#24D8D7","#a1a701","#6d4271","#01689b","#ffda08","#1f933a","#5b9bd4","#eb7bda","#68dbb2","#e54f2c"];
        let yData =  this.dataOptions
        this.dataOptions.forEach((item,i)=>{
            yData[i].value = (item.integral*1) + (item.sales*1) + (item.teamBuy*1)
            yData[i].name = item.title
        })
        let myChart = this.$echarts.init(this.$refs.culturalEcharts);
        let option = {
            color: color,
            legend: {
                top: 'bottom',
                left: 'center',
                itemWidth: 10,
                itemHeight: 10,
                textStyle: {
                    //图例文字的样式
                    color: "#fff",
                    fontSize: 12,
                    padding: 5,
                },
                formatter: function (name) {
                    return "" + name;
                },
            },
            series: [{
                type: 'pie',
                radius: ["50%", "65%"],
                top: '-30',
                minAngle: 2, //最小的扇区角度（0 ~ 360）
                avoidLabelOverlap: false,
                label: {
                    normal: {
                    show: false,
                    position: "center",
                    formatter: "{text|{b}}\n{c}",
                    rich: {
                        text: {
                            color: "#fff",
                            fontSize: 14,
                            align: "center",
                            verticalAlign: "middle",
                            padding: 8,
                        },
                        value: {
                            color: "#fff",
                            fontSize: 14,
                            align: "center",
                            verticalAlign: "middle",
                        },
                    },
                    },
                    emphasis: {
                        show: true,
                        textStyle: {
                            fontSize: 20,
                        },
                    },
                },
                data: yData
            }]
        };
        myChart.setOption(option);

        myChart.currentIndex = -1;
        setInterval(function () {
            let dataLen = option.series[0].data.length;
            // 取消之前高亮的图形
            myChart.dispatchAction({
                type: "downplay",
                seriesIndex: 0,
                dataIndex: myChart.currentIndex,
            });
            myChart.currentIndex = (myChart.currentIndex + 1) % dataLen;
            // 高亮当前图形
            myChart.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: myChart.currentIndex,
            });
        }, 3000);
        
        myChart.on("mouseout", function () {
            // 高亮当前图形
            myChart.dispatchAction({
                type: "downplay",
                seriesIndex: 0,
                dataIndex: myChart.currentIndex,
            });
        });

        window.addEventListener("resize", function () {
            myChart.resize();
        });
    },
}
</script>
<style lang='scss' scoped>
    @import './index.scss';
</style>
<template>
  <div class="">
    <!-- 弹框 -->
    <el-dialog
      title=""
      :visible.sync="dialog"
      width="70vw"
      :append-to-body="true"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
        <div class="dialog-bg">
            <img class="back-ground-image" src="@/assets/image/idx-tk.png" alt="" />
        </div>
        <!--  -->
        <div class="dialog-box">
            <div class="dialog-box-top">{{ dialogTitle }}</div>
            <div class="dialog-cantent">
                <div class="dialog-left">
                    <el-select
                        v-model="culturalValue"
                        placeholder="请选择"
                        @change="changezhou($event)"
                    >
                        <el-option
                            v-for="(item, index) in dataOptions"
                            :key="index"
                            :label="item.title"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <!-- <el-button type="primary" @click="onSubmit">查询</el-button> -->
                    <div class="dl-txt" @click="partyDialogFun">{{culturalData.culturalDesc}}</div>
                    <div class="dl-btnbox">
                        <div class="dl-item" @click="partyDialogFun">
                            <div class="dli-txt">团购量</div>
                            <div class="dli-num">{{culturalData.teamBuy}}</div>
                        </div>
                        <div class="dl-item"  @click="partyDialogFun">
                            <div class="dli-txt">积分兑换</div>
                            <div class="dli-num">{{culturalData.integral}}</div>
                        </div>
                        <div class="dl-item"  @click="partyDialogFun">
                            <div class="dli-txt">销售</div>
                            <div class="dli-num">{{culturalData.sales}}</div>
                        </div>
                    </div>
                </div>
                <div class="dialog-right" v-if="imgList.length>0">
                    <div class="dr-item" v-for="(item, i) in imgList" :key="i" @click="gotomarketingaid">
                        <img :src="'/dev-api'+item" />
                    </div>
                </div>
                <div class="dialog-right zero" v-else @click="gotomarketingaid">
                    <!-- image="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png" -->
                    <!-- <el-empty description="暂无站点图片" :image="imgurl"></el-empty> -->
                    <img class="emptyimg" src="@/assets/image/empty.png" />
                    <div class="emptytxt">暂无站点图片</div>
                </div>
            </div>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { listData} from "@/api/dtdata";
export default {
  props: ['dialog', "dialogTitle","dataOptions"],
  data() {
    return {
        culturalValue:'',
        culturalData:{
            culturalDesc: '',//内容
            teamBuy: '',//团购量
            integral: '',// 积分兑换
            sales: '',// 销售
        },
        imgList:[],
        // imgList_xsdwm:[
        //     '/img/cultura/cultural_xsdwm1.jpg',
        //     '/img/cultura/cultural_xsdwm1.jpg',
        //     '/img/cultura/cultural_xsdwm1.jpg',
        //     '/img/cultura/cultural_xsdwm1.jpg',
        // ],// 东泰社区新时代文明实践站
        // imgList_mygm:[
        //     '/img/cultura/cultural_mygm1.jpg',
        //     '/img/cultura/cultural_mygm2.jpg',
        //     '/img/cultura/cultural_mygm3.jpg',
        //     '/img/cultura/cultural_mygm4.jpg',
        // ],//民盈国贸党群服务中心
        // imgList_ygyd:[
        //     '/img/cultura/cultural_ygyd1.jpg',
        //     '/img/cultura/cultural_ygyd2.jpg',
        //     '/img/cultura/cultural_ygyd3.jpg',
        //     '/img/cultura/cultural_ygyd1.jpg',
        // ],//阳光雨党群
        // imgList_lndx:[
        //     '/img/cultura/cultural_lndx1.jpg',
        //     '/img/cultura/cultural_lndx1.jpg',
        //     '/img/cultura/cultural_lndx1.jpg',
        //     '/img/cultura/cultural_lndx1.jpg',
        // ],//东泰社区老年大学
    };
  },
  mounted(){
    this.getData()
  },
  methods: {
    gotomarketingaid(){
        this.$emit('gotomarketingaid', this.culturalValue)
        // if(this.existSystem()){
        //     this.$router.push({path:'/marketingaid',query:{sel: this.culturalValue}})
        // }
    },
    getData(){
        if(this.dataOptions&&this.dataOptions.length>0){
            this.culturalValue = this.dataOptions[0].id
            this.culturalData = this.dataOptions[0]
            this.getImgData()
        }
    },
    handleClose() {
        this.$parent.isDialog = false;
    },
    partyDialogFun(){
        this.$emit('partyDialogFun', this.culturalData)
    },
    changezhou(id) {
        this.dataOptions.forEach(item => {
            if(item.id == id){
                this.culturalData = item
                this.culturalValue = item.id
                this.getImgData()
                // this.$nextTick(()=>{
                //     this.imgList = []
                //     if(id== 1){
                //         this.imgList = this.imgList_xsdwm
                //     }else if(id== 2){
                //         this.imgList = this.imgList_mygm
                //     }else if(id== 3){
                //         this.imgList = this.imgList_ygyd
                //     }else if(id== 4){
                //         this.imgList = this.imgList_lndx
                //     }
                // })
            }
        });
    },
    getImgData(){
        let loadings = this.$loading({
            lock: true,
            text: '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        listData({
            dataModule: 'wjdt_cultural_banner_data',
            dataTyp: this.culturalValue,
        }).then(res=>{
            if(res.code == 200){
                this.imgList = []
                res.rows.forEach(item => {
                    item.dataField1 = (item.dataField1&&item.dataField1!='false')?true:false
                    if(item.dataField1){
                        this.imgList.push(item.dataDetailed)
                    }
                });
            }
        }).finally(()=>{
            loadings.close();
        })
    },
    // 查询
    onSubmit(){
        this.dataOptions.forEach(item => {
            if(item.id == this.culturalValue){
                this.culturalData = item
                this.culturalValue = item.id
            }
        });
    }
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-dialog {
    position: relative;
    margin: 0 auto 50px;
    border-radius: 2px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 99999;
    width: 50%;
    height: 66vh;
    background: rgba(255, 255, 255, 0);
    .el-dialog__header {
        padding-top: 4vh;
    }
    .el-dialog__body {
        padding: 0.2vw 0.2vh;
        color: #606266;
        font-size: 14px;
        word-break: break-all;
    }
    .dialog-bg {
        position: relative;
    }
    .back-ground-image {
        width: 100%;
        height: 65vh;
        position: relative;
    }
    .dialog-box {
        position: absolute;
        top: 6.5vh;
        right: 2.3vw;
        left:2.3vw;
        .dialog-box-top {
            width: 100%;
            height: 6vh;
            line-height: 6vh;
            text-align: center;
            font-size: 1.2vw;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffd901;
        }
    }
     .el-dialog__headerbtn {
        width: 5vw;
        height: 7vh;
        // border: 1px solid teal !important;
        opacity: 0;
        position: absolute;
        top: 4vh;
        right: -2vw;
        padding: 0;
        background: 0 0;
        border: none;
        outline: 0;
        cursor: pointer;
        font-size: 16px;
        z-index: 999999;
    }
}
.dialog-cantent{
    padding-top: 3vh;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .dialog-left{
        width: 40%;
        .dl-btnbox{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 5vh;
            padding: 0 .5vw;
            .dl-item{
                width: 28%;
                background: linear-gradient(#145DCA, #1534C0);
                text-align: center;
                flex-wrap: wrap;
                font-size: 1vw;
                color: #fff;
                padding: 1vh 0;
                border-radius: .9vw;
                line-height: 3vh;
                >div{
                    width: 100%;
                }
                .dli-num{
                    font-size: .8vw;
                    font-family: zcoolqingkehuangyouti;
                    font-weight: 400;
                    color: #00EAFF;
                }
            }
        }
        ::v-deep .el-select {
            display: inline-block;
            position: relative;
            margin-left: 0.3vw;
            margin-right: 2vw;
        }
        ::v-deep .el-input {
            position: relative;
            font-size: 14px;
            display: inline-block;
            width: 17vw;
        }
        ::v-deep .el-input__inner {
            -webkit-appearance: none;
            background-color: rgba(255, 255, 255, 0);
            background-image: none;
            border-radius: 4px;
            border: 1px solid #0f2f6f;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            color: #bfdaff;
            display: inline-block;
            font-size: 0.8vw;
            height: 4vh;
            line-height: 4vh;
            outline: 0;
            padding: 0 15px;
            -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            width: 17vw;
        }
        ::v-deep .el-button {
            height: 5vh;
            display: inline-block;
            line-height: 1;
            white-space: nowrap;
            cursor: pointer;
            // background: #fff url(../../../assets/image/dy-btn.png);
            background: url(../../../assets/image/dy-btn.png) center center no-repeat;
            border: none;
            color: #7faaff;
            -webkit-appearance: none;
            text-align: center;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            outline: 0;
            margin: 0;
            -webkit-transition: 0.1s;
            transition: 0.1s;
            font-weight: 500;
            padding: 12px 20px;
            font-size: 14px;
            border-radius: 4px;
            // margin-left: 2vw;
        }
    }
    .dl-txt{
        color: #fff;
        font-size: .9vw;
        line-height: 1.8vw;
        margin-top: 1vh;
        height: 30vh;
        overflow-y: auto;
        
    }
    .dialog-right{
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        margin:0 0 0 4vw;
        padding-right: 2vw;
        height: 50vh;
        overflow: auto;
        &.zero{
            justify-content: center;
            align-content: center;
            flex-wrap: wrap;
        }
        .dr-item{
            width: 15vw;
            height: 13vw;
            background-color: #ccc;
            margin-bottom: 1vh;
            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        .emptyimg{
            width: 15vw;
        }
        .emptytxt{
            width: 100%;
            font-size: 1vw;
            text-align: center;
            padding-top: 3vh;
            color: #f1f1f1;
            letter-spacing: 1px;
        }
    }
}
</style>
